import * as React from "react";
import Layout from "../../components/layout";
import { FocusAreaIcon } from "../../components/svg";
import { Helmet } from "react-helmet";
import LevercodePyramid from "../../images/LevercodePyramid.png";
import LevercodeStack from "../../images/LevercodeStack.png";

//import { StaticImage } from "gatsby-plugin-image";

const GovernmentPage = () => (
  <Layout>
    <Helmet title="e-Governance - Levercode" />
    {/* Sub Header image */}
    <div className="_government_hero mt-32">
      <div className="flex">
        <FocusAreaIcon icon="government" className="mx-auto w-auto h-32 sm:h-80 -mt-20 text-yellow-800 fill-current" />
      </div>

      {/* Sub Header intro text */}
      <div className="mx-auto py-12 sm:py-24 text-white">
        <h1 className="text-4xl sm:text-7xl leading-normal font-bold py-10 text-center">
          Fundamentals of{" "}
          <span className="whitespace-nowrap">
            <span className="text-yellow-800 text-5xl sm:text-8xl">[</span> Digital Transformation <span className="text-yellow-800 text-5xl sm:text-8xl">]</span>
          </span>
        </h1>
        <p className="sm:px-2/12 text-xl sm:text-3xl leading-relaxed text-center pb-40">Levercode’s Approach to e-Governance</p>
      </div>
    </div>
    {/* Content */}
    <div className="container max-w-screen-xl py-32 text-2xl">
      {/*<h2 className="text-5xl text-blue-800 ">Levercode’s Approach to e-Governance</h2>*/}

      <p className="mt-16">
        Nearly all countries and Governments aspire to use digital technologies in providing services to its citizens and residents. However, way too often, after taking political, visionary decision, major focus lands on implementation of specific
        applications and solutions, <strong>leaving little attention on basic foundational data systems that are of vital importance for effective digital governance and integrity of the whole ecosystem</strong>.
      </p>

      <p className="mt-16">
        In Levercode’s view, it is impossible to complete <strong>Digital Transformation</strong> and achieve a reliable e-Governance ecosystem that is sustainable and operates on low data risk without establishing <strong>Digital Identity</strong>,{" "}
        <strong>Data Exchange Layer</strong> (known also as X-Road), and <strong>Registries</strong>. These three critical components, if set up in right way, create solid foundation for e-Governance system. This is exactly how Levercode can build value to
        your Government, country, or organization.
      </p>

      <p className="mt-16">
        <img src={LevercodePyramid} alt="Levercode Pyramid" />
      </p>

      <p className="mt-16">
        We believe that there is no „one size fits all “solution when it comes to your mission critical systems. Every country is different making the needs, objectives and preconditions different too.{" "}
        <strong>Levercode pays great attention to the Discovery Phase</strong> where, together with your participation and involvement, our experts learn about the wider landscape and identify the issues to be solved, as well as gaps to be filled. The
        outcome of this phase gives you full understanding of the starting point and detailed <strong>road map (Blueprint) for achieving a sustainable Digital Society</strong>.
      </p>

      <p className="mt-16">
        We design and model the e-Governance systems from the scratch. However, our Research Lab is capable of auditing and evaluating already existing systems' weaknesses, data and process risks, and provide recommendations for remedy. Naturally, we can
        also step in to fix or redesign the systems based on industry standards, clients’ needs, and preferences.
      </p>

      <p className="mt-16 font-bold border-l-4 border-blue-800 pl-8 text-black">Our unique expertise and modular approach allow us to create systems that are scalable from 1.3 million people Estonia to societies with hundreds of million residents.</p>

      <p className="mt-16">
        Levercode is not only about creating foundation for e-Governance. We are also experts in turning trust-based solutions into proof-based platforms by{" "}
        <strong>implementing Proof Technologies, including Blockchain and other Distributed Ledger Technologies (DLT)</strong>. Application of such tools has proven itself in the handling and protection of sensitive data stored in medical, land,
        population, and many other critical records.
      </p>

      <p className="mt-16">
        In addition, Levercode excels in building data governance systems that <strong>provide compliance with the surrounding regulatory environment including GDPR and AML</strong>. We have deep knowledge in many important service verticals such as
        healthcare and fintech to name a few. “Levercode Stack” gives an overview of full ecosystem in which Levercode is capable of contributing meaningful value.
      </p>

      <p className="mt-16">
        <img src={LevercodeStack} alt="Levercode Stack" />
      </p>

      <p className="mt-16">Please contact us to learn more about Levercode’s approach to achieving Digital Transformation and the ways we can build value for your country or organization.</p>
    </div>
  </Layout>
);

export default GovernmentPage;
